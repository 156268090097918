/* CreditHistory.css */

.account-details-container {
    margin-top: 50px;
  }
  
  .account-details-content {
    margin-top: 20px;
  }
  
  .update-option {
    font-weight: bold;
    color: #007bff;
    position: relative;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14l-4.796-5.481C2.198 5.253 2.62 4.5 3.324 4.5h9.352c.704 0 1.126.753.873 1.159l-4.796 5.481c-.58.663-1.672.663-2.252 0z'/%3E%3C/svg%3E") no-repeat right 10px center;
    background-size: 16px 16px;
  }
  
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  button.mb-3 {
    margin-top: 10px;
  }
  
  form .form-group {
    margin-bottom: 15px;
  }
  
  form .form-control {
    padding: 10px;
    border-radius: 5px;
  }
  
  .dropdown-icon {
    margin-left: 5px;
  }
  
  .table th,
  .table td {
    vertical-align: middle;
  }