/* PurchaseCredits.css */

.purchase-credits-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .purchase-credits-card {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
  }
  
  .purchase-credits-card h1 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .purchase-credits-card .form-group {
    margin-bottom: 20px;
  }
  
  .purchase-credits-card .form-check-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .purchase-credits-card .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
  }
  
  .purchase-credits-card .alert {
    margin-top: 20px;
  }

.refresh-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  color: #007bff;
}

.refresh-button:disabled {
  color: #6c757d;
}
