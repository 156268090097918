/* General App Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Layout Styles */
.main-content {
  display: flex;
}

body, html, #root, .app-wrapper {
  height: 100%;
  margin: 0;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
}

.navbar-wrapper {
  flex: 0 0 auto;
}

.main-wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.left-sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #dee2e6;
  flex: 0 0 auto;
  height: 100%;
}

.content {
  flex: 1 1 auto;
  padding: 15px;
  overflow-y: auto;
}

/* Sidebar Styles */
.left-sidebar .nav-link {
  padding: 10px 15px;
  color: #000;
}

.left-sidebar .nav-link:hover {
  background-color: #e9ecef;
  color: #000;
}

.nav-header {
  font-weight: bold;
  color: #333;
}

.left-sidebar .nav-link {
  padding-left: 20px;
}

/* Login Container Styles */
.login-container {
  margin: auto;
  width: 300px;
  text-align: center;
}

.login-container input {
  display: block;
  margin: 10px 0;
  width: 100%;
  padding: 10px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

/* Card Styles */
.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
}

/* Login Container Styles */
.login-container {
  margin: auto;
  width: 300px;
  text-align: center;
}

.login-container input {
  display: block;
  margin: 10px 0;
  width: 100%;
  padding: 10px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

/* Card Styles */
.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
}

/* Ensure the navbar-toggler is positioned at the top left */
.sidebar-toggler {
  position: fixed;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
}

/* Adjust the collapse menu to be below the navbar-toggler */
#sidebar-navbar-nav-responsive {
  width: 200px;
  z-index: 1000;
  top: 10px; /* Adjust as needed */
  padding: 10px 15px;
}

#sidebar-navbar-nav-responsive .nav-link {  
  padding: 10px 15px;
}

.sidebar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3; /* or any preferred background color */
}

.loading {
  font-size: 24px;
  color: #333;
  animation: fadeInOut 1s infinite;
}



@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}


@media (max-width: 768px) {
  .navbar-brand {
    margin-left: 75px; /* Adjust this value to create space for the hamburger icon in responsive view */
  }
}