/* MythicMS.css */

.mythicms-container {
    margin-top: 50px;
  }
  
  .card {
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
  }
  
  .card ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    display: inline-block;
  }
  
  .card p,
  .card ul {
    text-align: left;
    margin: 0 auto;
  }
  